import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

let theme = createTheme({});
theme = createTheme({
  palette: {
    // white
    primary: {
      main: '#ffffff',
    },
    // blue
    secondary: {
      main: '#ffaf20',
    },
    // gold
    custom: {
      main: '#10455B',
    },
    // light grey
    custom2: {
      main: '#bdbdbd',
    },
  },
  typography: {
    p: {
      color: '#bdbdbd',
      fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: '1.2rem',
      lineHeight: 1.5,
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    h1: {
      color: 'white',
      fontWeight: 'bold',
      fontSize: '350%',
      lineHeight: 1.2,
      fontFamily: [
        '-apple-system,BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Noto Sans"',
        'sans - serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ].join(','),
    },
    h2: {
      color: 'white',
      fontSize: '2.7rem',
      lineHeight: 1.2,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        "'Segoe UI'",
        'Roboto',
        "'Helvetica Neue'",
        'Arial',
        "'Noto Sans'",
        'sans-serif',
        "'Apple Color Emoji'",
        "'Segoe UI Emoji'",
        "'Segoe UI Symbol'",
        "'Noto Color Emoji'",
      ].join(','),
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.7rem',
      },
    },
    h3: {
      fontSize: '1.6rem',
      lineHeight: 1.2,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        "'Segoe UI'",
        'Roboto',
        "'Helvetica Neue'",
        'Arial',
        "'Noto Sans'",
        'sans-serif',
        "'Apple Color Emoji'",
        "'Segoe UI Emoji'",
        "'Segoe UI Symbol'",
        "'Noto Color Emoji'",
      ].join(','),
      fontWeight: 400,
    },
    h4: {
      fontSize: '1.3rem',
      lineHeight: 1.1,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        "'Segoe UI'",
        'Roboto',
        "'Helvetica Neue'",
        'Arial',
        "'Noto Sans'",
        'sans-serif',
        "'Apple Color Emoji'",
        "'Segoe UI Emoji'",
        "'Segoe UI Symbol'",
        "'Noto Color Emoji'",
      ].join(','),
      fontWeight: 400,
    },
    highlight: {
      color: '#ffaf20',
    },
  },
});


const FloatingContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.custom.main,
  [theme.breakpoints.up('xs')]: {
    marginBottom: '20px',
    borderRadius: '0',
  },
  [theme.breakpoints.up('sm')]: {
    marginBottom: '100px',
    borderRadius: '12px',
  },
}));

const sectionIds = [
  'what-we-do',
  'benefits-for-you',
  'how-it-works',
  'locations',
];

const maxOpacity = 1;

function App() {
  const [scrollPos, setScrollPos] = useState(0);
  const sectionMap = useRef({});
  const [sectionOpacities, setSectionOpacities] = useState({});

  const handleScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setScrollPos(winScroll);

    const newOpacities = {};
    Object.entries(sectionMap.current).forEach((entry) => {
      const id = entry[0];
      const ele = entry[1].getBoundingClientRect();
      const topDist = window.innerHeight / 2 - ele.top;
      const botDist = window.innerHeight / 2 - ele.bottom;
      var centerDist = 0;
      if (Math.sign(topDist) === Math.sign(botDist)) {
        centerDist = Math.min(Math.abs(topDist), Math.abs(botDist));
      }
      const opacity = Math.max(
        0,
        maxOpacity -
          Math.pow(0.5 * window.innerHeight, -2) * Math.pow(centerDist, 2)
      );
      newOpacities[id] = opacity;
    });
    setSectionOpacities(newOpacities);
  };

  useEffect(() => {
    sectionIds.forEach((id) => {
      sectionMap.current[id] = document.getElementById(id);
    });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
  <>
      <ThemeProvider theme={theme}>
        <div
            className='background'
            onClick={() => {
            console.log('bg click');
            }}
        >

            <Container
            disableGutters
            maxWidth='100%'
            sx={{
                position: 'sticky',
                top: {
                xs: `${37 - scrollPos / 100}%`,
                md: `${40 - scrollPos / 100}%`,
                },
                textAlign: 'right',
                paddingRight: { sm: '10vw', xl: '20vw' },
                opacity: Math.max(0, 1.4 - scrollPos / 400),
            }}
            >
            <Typography
                variant='h1'
                sx={{
                fontSize: { xs: '200%', sm: '250%', md: '350%' },
                textAlign: { xs: 'center', md: 'right' },
                lineHeight: { xs: '2', md: '1.2' },
                }}
            >
                Frontier Vending
                <br></br>
                <Typography variant='highlight'>Services </Typography>
                LLC
            </Typography>
            </Container>
            <section
            className='scroll-down'
            style={{
                position: 'sticky',
                top: `${90 - scrollPos / 25}%`,
                opacity: Math.max(0, 1.4 - scrollPos / 100),
            }}
            onClick={() => {
                console.log('clicked');
            }}
            >
            <div className='arrow arrow-first' />
            <div className='arrow arrow-second' />
            </section>
        </div>
        <div
            style={{
            position: 'absolute',
            top: '20vh',
            height: '80vh',
            width: '100%',
            cursor: 'pointer',
            }}
            onClick={() => {
            const what_we_do_ele = document.getElementById('what-we-do');
            what_we_do_ele.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
            }}
        ></div>

        <div className='section-secondary'>
            <FloatingContainer
            id='what-we-do'
            className='section-container'
            sx={{
                opacity: `${sectionOpacities['who-we-are']}`,
            }}
            >
                <Typography variant='h2' mb={'20px'}>
                    What We Do
                </Typography>
                <Typography variant='p' mb={'20px'} id='who-we-are-text'>
                    Frontier Vending Services LLC aims to provide your business with
                    high quality vending machines and products at no expense to you.
                    We take care of installation, maintenance, and stocking (all the boring stuff).
                </Typography>
            </FloatingContainer>

            <FloatingContainer
            className='section-container'
            id='benefits-for-you'
            sx={{
                opacity: `${sectionOpacities['benefits-for-you']}`,
            }}
            >
                <Typography variant='h2' mb={'20px'}>
                    Benefits For You
                </Typography>
                <Typography variant='p' mb={'20px'}>
                    Your business will have an advertisable new ammenity. Keep in mind, a hungry
                    customer is not a happy customer. You won't have to worry about this because
                    we'll take care of it for you.
                </Typography>
            </FloatingContainer>

            <FloatingContainer
            className='section-container'
            id='how-it-works'
            sx={{
                opacity: `${sectionOpacities['how-it-works']}`,
            }}
            >
                <Typography variant='h2' mb={'20px'}>
                    How It Works
                </Typography>
                <Typography variant='p' mb={'20px'}>
                    1. Contact us by email (
                        <a
                        id='email-link'
                        className='no-underline'
                        href='mailto:services@frontiervending.co'
                        >
                        services@frontiervending.co
                        </a>
                    ) or by phone number (213-603-0386)
                </Typography>
                <Typography variant='p' mb={'20px'}>
                    2. Set up a free consultation
                </Typography>
                <Typography variant='p' mb={'20px'}>
                    3. Come to an agreement and get it in writing
                </Typography>
                <Typography variant='p' mb={'20px'}>
                    4. We install your location's vending machine for free
                </Typography>
                <Typography variant='p' mb={'20px'}>
                    5. No more hungry customers
                </Typography>
            </FloatingContainer>

            <FloatingContainer
            id='locations'
            className='section-container last-section'
            sx={{
                // transform: `scale(${sectionScales['contact']})`,
                opacity: `${sectionOpacities['contact']}`,
            }}
            >
                <Typography variant='h2' mb={'20px'}>
                    Locations We Serve
                </Typography>
                <Typography variant='p' mb={'20px'}>
                    We are currently serving the greater Los Angeles area!
                </Typography>
            </FloatingContainer>

        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
